// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui-pro/scss/coreui.scss";


@import "~react-confirm-alert/src/react-confirm-alert.css";

// Some temp fixes
@import "fixes";

// TextEditor css
@import "text-editor";

// If you want to add something do it here
@import "custom";