// Here you can add other styles
.c-pointer {
  cursor: pointer;
}
// loader css
.loading-gif {
  // background-image: url("../assets/loader-cloud.gif");
  // background-repeat: no-repeat;
  background-position: center;
  opacity: 0.5;
  z-index: 999;
  overflow: visible;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.cil-hamburger-menu {
  color: #0cb0b2 !important;
}
.theme-color {
  color: #0cb0b2 !important;
}
.theme-label {
  color: #0cb0b2 !important;
}
.set-left-border {
  border-left: 3px solid #0cb0b2 !important;
  padding-left: 10px;
  &.setLnHeight {
    line-height: 1.1;
  }
}
.theme-textarea {
  border: 1px solid #0cb0b2 !important;
}
.theme-btn {
  border: 1px solid #0cb0b2 !important;
  color: #0cb0b2 !important;
}
.theme-btn-warning {
  border: 1px solid #f4b183 !important;
  color: #f4b183 !important;
}
.theme-btn-error {
  background-color: #e6c8ee !important;
  border: 1px solid #9a4fb4 !important;
  color: #9a4fb4 !important;
}
.theme-bg-color {
  background-color: #0cb0b2 !important;
}
.btn-grey-out {
  opacity: 0.4 !important;
  pointer-events: none;
}
.mw-20 {
  min-width: 20px;
}
.mw-120 {
  min-width: 120px;
}
.mw-140 {
  min-width: 140px;
}

.c-body,
.theme-header {
  background-color: #dbeff0;
}
.text-end {
  text-align: end;
}
.f-14 {
  font-size: 14px;
}
.f-15 {
  font-size: 15px;
}
.f-16 {
  font-size: 16px;
}
.c-sidebar {
  .c-sidebar-brand {
    min-height: 76px;
    &:hover {
      text-decoration: none;
    }
  }
  .nav-logout {
    display: flex;
    flex: 0 0 50px;
    // justify-content: flex-end;
    width: inherit;
    padding: 0.8445rem 1rem;
    cursor: pointer;
    border: 0;
  }
}
.c-sidebar-nav {
  &.changeBack {
    background-color: #545655;
  }
  .hr {
    border: 1px solid grey;
  }
  .c-sidebar-nav-item {
    .custCheckBoxes {
      .custom-control-input:checked ~ .custom-control-label {
        &::before {
          background-color: #0cb0b2 !important;
          border-color: #0cb0b2 !important;
        }
      }
    }
    .customNavPad {
      padding: 0 1rem 0rem 1rem;
    }
    .check-icon {
      position: absolute;
      top: 5px;
      right: 10px;
      width: 15px;
      height: 15px;
      background-image: url("../assets/icons/tick-mark.png");
    }
    .sidebar-icons-holder {
      height: 175px;
      overflow-y: scroll;
    }
    .icon-img {
      padding: 10px;
      position: relative;
      .img-selected {
        border: 2px solid #0cb0b2;
        border-radius: 50%;
      }
    }
  }
}

.three-dash-menu {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 21, 0.5)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  display: block;
  height: 1.36719rem;
  background-repeat: no-repeat;
}

.sensor-header {
  .d-lg-none {
    width: 25px;
    margin: 0;
    display: inline-block;
  }
  .SensorListSidebarFilter {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 21, 0.5)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    display: block;
    height: 1.36719rem;
    background-repeat: no-repeat;
  }
}

.sensor-graph-index-header {
  background-color: #c5eff1;
  margin: -5px -30px 0 -30px;
  padding: 5px 15px 0 15px;
  button {
    min-width: 75px;
  }
}
.sensor-graph-header {
  margin-top: 15px;
  .mw-150 {
    min-width: 150px;
  }
  .mw-80 {
    min-width: 80px;
  }
}

.sensor-graph {
  height: calc(100vh - 280px);
  overflow-y: auto;
  padding-bottom: 0px;
  #graphDiv1 {
    width: 100%;
    height: calc(100vh - 330px);
    .dygraph-legend {
      background: rgba(0, 0, 0, 0.5);
      color: white;
      border-radius: 5px;
      max-width: 200px;
    }
    .dygraph-axis-label-x {
      font-size: 11px;
    }
    .line-to-draw {
      visibility: hidden;
      background-color: #737373;
      position: absolute;
      pointer-events: none; /* let mouse events fall through to the chart */
      &.xline {
        height: calc(100% - 20px);
        width: 1px;
      }
    }
  }
}

.red-dot {
  height: 10px;
  width: 10px;
  background-color: #ff0000;
  border-radius: 50%;
  display: inline-block;
}

.news-guide-buttons {
  width: 100px;
}

.three-dots {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: black;
  box-shadow: 0px 12px 0px black, 0px 24px 0px black;
}

.SensorHeader,
.device-sensors,
.BMsHeader {
  .sensor-list-body {
    max-height: calc(100vh - 170px);
    overflow: auto;
    display: flex;

    thead th {
      position: -webkit-sticky !important;
      position: sticky !important;
      top: 0 !important;
      z-index: 1000 !important;
    }

    .sensor-battery {
      text-align: right;
    }
  }
  .map-view {
    height: calc(100vh - 230px);
    // width: calc(100vw - 316px);
    // overflow: hidden;
    overflow: auto;
    display: flex;
    justify-content: center;
    // align-items: center;
    // .react-transform-wrapper {
    //   height: calc(100vh - 270px);
    //   width: calc(100vw - 316px);
    //   overflow: auto;
    // }
    .react-transform-wrapper {
      width: 100% !important;
      height: 100% !important;
      .react-transform-component {
        display: block !important;
      }
    }

    .sensorNum {
      font-size: large;
      color: #0eff00;
      position: absolute;
      font-weight: 900;
      -webkit-text-stroke: 0.09rem black;
    }

    .boxContent {
      background-repeat: no-repeat;
      position: relative;
      background-size: contain;
      border: 1px dashed #949494;
      // height: calc(100vh - 290px);
      // width: calc(100vw - 335px);
      .hotspot {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px solid rgba(255, 255, 255, 0);
        &.drag-cls {
          cursor: move;
        }
        &.del{
          cursor: not-allowed;
        }
        position: absolute;
        z-index: 2;
        &.icon-holder {
          .caution-border {
            border: 4px solid #ff0000;
            border-radius: 50%;
            &.active {
              border: 4px solid white;
            }
          }
          .warning-border {
            border: 2px solid #fe8700;
            border-radius: 50%;
          }
          .error-border {
            border: 2px solid #c457ec;
            border-radius: 50%;
          }
          .ok-border {
            border: 2px solid #04eeff;
            border-radius: 50%;
          }
        }
        &.active {
          border: 4px solid red;
          border-radius: 50%;
        }
      }
      .line-to-draw {
        border-top: 1px dashed #cacaca;
        position: absolute;
        opacity: 0.8;
        z-index: 1;
        pointer-events: none; /* let mouse events fall through to the chart */
        &.xline {
          width: 100%;
        }
        &.yline {
          height: 100%;
          border-left: 1px dashed #cacaca;
        }
      }
    }
  }
  .map-icon-size-buttons,
  .map-map-zoom-buttons {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    span {
      font-size: 15px;
    }
  }
  .c-white {
    color: white;
  }
  .table {
    margin-bottom: 0;
  }
  thead {
    th {
      border-right: 1px solid;
      border-right-color: #d8dbe0;
      border-top: unset !important;

      color: white;
      background-color: #09b1b2;
    }
    th {
      &:last-child {
        border-right: unset;
      }
    }
  }
  tbody {
    tr {
      td,
      th {
        border-top: unset;
        border-right: 1px solid;
        border-right-color: #d8dbe0;
      }
      td,
      th {
        &:last-child {
          border-right: unset;
        }
      }
      td {
        font-size: 16px;
      }
      &.tr-caution {
        background-color: #f29a99;
      }
      &.tr-warning {
        background-color: #fe8700;
      }
      &.tr-error {
        background-color: #d9c5e0;
      }
      .Bm-Name {
        color: #3c4b64;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
.sensor-info-modal {
  .modal-body {
    p {
      label {
        min-width: 140px;
        text-align: end;
      }
    }
  }
}
.BMsHeader {
  thead {
    background-color: #09b1b2;
    color: white;
    th {
      border-right: 1px solid;
      border-right-color: #d8dbe0;
    }
    th {
      &:last-child {
        border-right: unset;
      }
    }
  }
  tbody {
    tr {
      outline: 1px solid transparent; 
      &:hover {
        outline: 1px solid red; 
      }
      td,
      th {
        border-top: unset;
        border-right: 1px solid;
        border-right-color: #d8dbe0;
      }
      td,
      th {
        &:last-child {
          border-right: unset;
        }
      }
    }
  }
}

.icon-disable {
  opacity: 0.4;
  pointer-events: none;
}

.SensorBMSettingsModal {
  .modal-xl {
    max-width: 90vw;
  }
  .modal-content {
    background-color: #dbeff0;
    .modal-body {
      max-height: 80vh;
      overflow-y: auto;
    }
    .set-custom-height {
      background-color: white;
      height: 250px;
      overflow-y: auto;
      .map-bg {
        height: 200px;
        width: 200px;
      }
    }
    .set-custom-height-new {
      background-color: white;
      height: 390px;
      overflow-y: auto;
      .custCheckBoxes {
        .custom-control-input:checked ~ .custom-control-label {
          &::before {
            background-color: #0cb0b2 !important;
            border-color: #0cb0b2 !important;
          }
        }
      }
    }
    .select-bordered,
    .timepicker-bordered {
      border-radius: 0;
      border: unset;
      border-bottom: 2px solid grey;
    }
    .dark-border-bottom {
      border-bottom: 2px solid grey;
    }
    header {
      border-bottom: unset;
    }
    footer {
      border-top: none;
    }
    .custom-bg {
      background-color: white;
      padding: 10px;
      overflow-x: auto;
    }
    table {
      thead {
        background-color: #09b1b2;
        color: white;
        th {
          border-right: 1px solid;
          border-right-color: #d8dbe0;
        }
        th {
          &:last-child {
            border-right: unset;
          }
        }
      }
      tbody {
        tr {
          td,
          th {
            border-top: unset;
            border-right: 1px solid;
            border-right-color: #d8dbe0;
          }
          td,
          th {
            &:last-child {
              border-right: unset;
            }
          }
        }
      }
    }
  }
}

.SensorBMReportModal {
  font-size: 18px;

  .modal-dialog {
    width: 650px;
    max-width: 650px;
  }

  .modal-content {
    background-color: #fff;
    header.modal-header {
      border-bottom: unset;
      display: block;

      h5 {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
      }

      .close {
        position: absolute;
        top: 5px;
        right: 5px;
        margin: 0;
        padding: 0;
      }
    }
    .modal-body {
      .date-input-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .date-picker-container {
          width: 150px;
          margin: 0 5px;

          input {
            border: 0;
            border-bottom: 1px solid #000;
            width: 150px;
          }

          .icon-calendar {
            width: 20px;
            height: 20px;
          }
        }
      }
      .arrow {
        text-align: center;
        color: #949494;
        font-size: 18px;
        margin: 10px;
      }

      .mode-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        input {
          &:not(:first-child) {
            margin-left: 15px;
          }

          &.report-loading {
            cursor: not-allowed;
          }
        }
      }

      .report-loading-text {
        height: 20px;
        text-align: center;
        margin-top: 20px;
      }

      .download {
        background-color: #fff;
        border-radius: 7px;
        outline: 0;
        box-shadow: none;
        font-weight: bold;
        border: 2px solid #0cb0b2;
        width: 145px;
        height: 60px;
      }
    }
  }
}

.AlertHistoryModal,
.AlaramListModal {
  .modelHeader {
    padding: 1rem 1rem;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    border-color: #d8dbe0;
    display: flex;
    justify-content: center;
    .modal-title {
      font-size: 1rem;
      font-weight: 500;
    }
  }
  .AlaramListBody {
    max-height: calc(100vh - 350px);
    overflow-y: auto;
  }
  thead {
    background-color: #09b1b2;
    color: white;
    th {
      border-right: 1px solid;
      border-right-color: #d8dbe0;
    }
    th {
      &:last-child {
        border-right: unset;
      }
    }
  }
  .table {
    border: 1px solid #d8dbe0;
  }
  tbody {
    td,
    th {
      border-top: unset;
      border-right: 1px solid;
      border-right-color: #d8dbe0;
    }
    td,
    th {
      &:last-child {
        border-right: unset;
      }
    }
  }
  footer {
    border-top: unset;
    padding-top: 0;
  }
}

.GraphSettingsModal {
  .close {
    color: #0cb0b2;
  }
  .modal-body {
    img {
      cursor: pointer;
    }
  }
  .custom-control-input:checked ~ .custom-control-label {
    &::before {
      background-color: #0cb0b2 !important;
      border-color: #0cb0b2 !important;
    }
  }
}

.SensorSettingsModal {
  .modal-dialog {
    min-width: 95%;
    .modal-body {
      max-height: 82vh;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  @media (min-width: 1441px) {
    .modal-dialog {
      min-width: 70% !important;
    }
  }
  .sensor-bg-color {
    background-color: #dbeff0;
  }
  .setBorder {
    border: 1px solid #d8dbe0;
    min-height: 100px;
    .set-bottom-border {
      border-bottom: 2px solid;
    }
    .dark-border-bottom {
      border-bottom: 2px solid grey;
    }
    &.set-height-equal {
      // height: 93%;
      height: 90%;
    }
  }
  footer {
    border-top: unset;
    padding-top: 0;
    button {
      min-width: 85px;
    }
  }
}

.CustomUserModals {
  .cust-header {
    background-color: #dbeff0;
  }
  .setBorder {
    border: 1px solid #d8dbe0;
    min-height: 100px;
  }
  footer {
    border-top: unset;
    padding-top: 0;
    button {
      min-width: 85px;
    }
  }
}

.UserModals {
  .modal-content {
    background-color: #c4c4c4;
    border-radius: 15px;
    .modal-header {
      border-bottom: unset;
      padding-bottom: unset;
    }
  }
}

.UserListDataTable {
  .table {
    margin-bottom: unset;
    thead {
      th {
        border-right: 1px solid;
        border-right-color: #d8dbe0;
      }
      th {
        &:last-child {
          border-right: unset;
        }
      }
    }
    tbody {
      td,
      th {
        border-top: unset;
        border-right: 1px solid;
        border-right-color: #d8dbe0;
      }
      td,
      th {
        &:last-child {
          border-right: unset;
        }
      }
    }
  }
}

.react-confirm-alert-overlay {
  z-index: 1051 !important;
}

.android-install-modal {
  .modal-content {
    height: 90vh;
    .add-cust-margin {
      margin-top: 30%;
    }
    img {
      border-radius: 8px;
    }
    .enter-btn {
      width: 120px;
    }
    .install-btn {
      width: fit-content;
      min-width: 150px;
    }
    .display-bottom {
      position: absolute;
      bottom: 40px;
      width: 95%;
    }
  }
}

.no-bullets {
  list-style-type: none;
  padding: 0;
  margin: 5px;
}

.react-datepicker__navigation-icon--previous,
.react-datepicker__navigation-icon--next {
  width: 0;
  top: 5px !important;
}

.react-time-picker__wrapper {
  border: none !important;
}

.disable-cursor-events {
  pointer-events: none !important;
  opacity: 0.5;
}

.email-list-table.table.fixed {
  table-layout: fixed;
  td {
    overflow: hidden;
    word-break: break-all;
  }
}

.react-time-picker__inputGroup__leadingZero {
  color: #000;
}

@media (min-width: 1440px) {
  .c-sidebar-nav {
    .c-sidebar-nav-item {
      .icon-img {
        padding-right: 10px;
        padding-bottom: 10px;
      }
    }
  }
  .haveRightMargin {
    margin-right: 250px !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .haveRightMargin {
    margin-right: 150px !important;
  }
}

@media (min-width: 1025px) {
  .version-text {
    margin-top: 24px;
  }
}

@media (max-width: 1210px) {
  .haccp-app-logo {
    width: 350px;
    height: 50px;
  }
}

@media (max-width: 1080px) {
  .c-sidebar-nav-item {
    .sidebar-custom-buttons {
      width: 100px;
      &.mw-120 {
        min-width: 100px;
      }
    }
  }
  // .haccp-app-logo {
  //   width: 290px;
  //   height: 45px;
  // }
}

@media (max-width: 1024px) {
  .haccp-app-logo {
    width: 300px;
  }
  .version-text {
    margin-top: 14px;
  }
  .c-sidebar-nav-item {
    .sidebar-custom-buttons {
      width: 100px;
      &.mw-120 {
        min-width: 100px;
      }
    }
  }
}

@media (max-width: 768px) {
  .haccp-app-logo {
    width: 240px;
  }
}
@media (max-width: 425px) {
  .haccp-app-logo,
  .haccp-header-app-logo {
    width: 200px;
  }
  .version-text {
    margin-top: 5px;
  }
  .sensor-footer-buttons {
    img {
      width: 100px;
    }
  }
  .SensorHeader {
    .sensor-list-body {
      max-height: calc(100vh - 220px);
    }
  }
  .BMsHeader {
    .filter-labels {
      label {
        font-size: 14px;
        h5 {
          font-size: 14px;
        }
      }
    }
  }
  .sensor-graph-header {
    &.f-16 {
      font-size: 14px;
    }
  }
  .sensor-graph {
    height: calc(100vh - 460px);
    #graphDiv1 {
      height: calc(100vh - 500px);
    }
  }
  .sensor-graph-buttons {
    img {
      width: 100px;
    }
  }
}
@media (max-width: 320px) {
  .haccp-app-logo,
  .haccp-header-app-logo {
    width: 170px;
  }
  .sensor-footer-buttons {
    img {
      width: 90px;
    }
  }
}
.fs-14 {
  font-size: 14px;
}

.user-news-modal {
  color: #004455;
}
.user-guide-modal {
  color: #004455;
  .btn-block {
    border: 1px solid #004455 !important;
  }
}

.lazy-loading-container {
  position: fixed;
  width: 240px;
  height: 30px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 30px;
  top: 30px;
  left: calc(50% - 110px);
}

.react-confirm-alert-body {
  white-space: pre-wrap;
}
