// CSS for texteditor
@import "~quill/dist/quill.snow.css";

.app-quill {
  .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }

  .ql-toolbar {
    display: block;
    background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .ql-editor {
    min-height: 18em;
  }
}